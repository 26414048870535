/* 
color palete
dark blue: #022099
blue : #2E50DC
aqua blue: #5DFDCB
purple: #D844FC
green: #4ED42C
*/

body {
  margin: 0;
  padding: 2em;
  font-family: Tiny5;
  background-color: #5dfdcb;
}

.App {
  margin: 0 auto;
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app-head {
  font-size: 0.6em;
  margin: auto;
  width: 80%;
  text-align: center;
  padding: 2em;
  font-family: Tiny5;
  background-color: #022099;
  border-radius: 4px;
  overflow: auto;
  box-shadow: -13px 13px #d844fc;
}

.app-head h1 {
  margin-bottom: 0.6em;
  color: #d844fc;
  font-size: 4em;
  text-shadow: -1px 1px #5dfdcb;
}

.app-head h4 {
  margin: 0;
  padding: 0;
  color: #5dfdcb;
  font-size: 1.3em;
  text-align: right;
}

.poem {
  font-size: 5vw;
  padding: 0em;
  margin-bottom: 0em;
  overflow: auto;
}

.poem-title {
  font-size: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.2em;
}

.data-poem {
  font-size: 0.6em;
  color: #2e50dc;
}

.poem-block {
  font-family: BebasNeue;
  font-size: 1em;
  padding: 0 2em 1em 2em;
  margin: 0em auto 1em auto;
  max-width: 80%;
  width: 70vw;
  border-radius: 10px;
  text-align: left;
  background-color: #6ff854;
  color: #022099;
}

.about {
  padding: 2em;
  margin: 2em auto;
  max-width: 80%;
  border-radius: 10px;
  background-color: #6ff854;
  color: #022099;
  font-family: Tiny5;
}

.about a{
  text-decoration: none;
  color: #2e50dc;
  display: block;
  margin-bottom: 4px;
  font-size: 1.5em;
}

.about a:hover{
  color:  #D844FC;
}

.donate{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.btn-container{
  margin: auto;
  display: block;  
  text-align: center;
}


.about-btn { 
  display: inline-block;
  margin-top: 30px;
  color: #5dfdcb;
  background-color: #2e50dc;
  padding: 0.5em;
  font-size: 1.5em;
  border-radius: 5px;
  border: 0;
  font-family: Tiny5;
  text-shadow: 2px 2px #d844fc;
}

.about-btn:hover {
  background-color: #d844fc;
  text-shadow: 2px 2px #2e50dc;
}

.pagination {
  flex-direction: column;
  padding: 1em;
  font-size: 1.8em;
  color: #d844fc;
  text-align: center;
  margin: auto;
}

.pagination p {
  margin: 0.1em;
}

.page-link {
  margin: auto 0.2em 0.2em 0.2em;
  border: none;
  font-family: Tiny5;
  font-size: 1em;
  padding: 0.2em 0.5em;
  border-radius: 10px;
  background-color: #6ff854;
  color: #d844fc;
  border: 4px solid #2e50dc;
  text-align: center;
}

.page-link.currentPage {
  background-color: #d844fc;
  color: #5dfdcb;
  border: 4px solid #022099;
}

.page-link:hover {
  background-color: #d844fc;
  color: #2e50dc;
  border: 4px solid #022099;
}



@font-face {
  font-family: Tiny5;
  src: url(../fonts/Tiny5-Regular.ttf);
}

@font-face {
  font-family: BebasNeue;
  src: url(../fonts/BebasNeue-Regular.ttf);
}

@media only screen and (min-width: 640px) {
  .app-head h1 {
    font-size: 5em;
  }
}

@media only screen and (min-width: 768px) {
  .btn-container{
    display: flex; 
    justify-content: center;
    align-items: center;
    gap:20px
  }
  .app-head h1 {
    font-size: 6em;
    margin-bottom: 0.5em;
  }
  .poem {
    font-size: 3vw;
  }
  .app-head h4 {
    font-size: 2em;
  }
  .data-poem {
    font-size: 0.8em;
  }
  .poem-title {
    font-size: 1em;
  }
  .poem-block {
    font-size: 2em;
    padding: 1em 2em;
  }
}
